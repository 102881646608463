// src/Collapse/Collapse.js
import React2, { useEffect, useRef, useReducer, useCallback } from "react";
import { Box, Text, Image, Placeholder, useConstructorMode } from "@quarkly/widgets";
import { useOverrides } from "@quarkly/components"; // src/Collapse/props/overrides.js

var overrides_default = {
	Button: {
		kind: "Box",
		props: {
			children: "Toggle",
			"focus-box-shadow": "none"
		}
	},
	Content: {
		kind: "Box",
		props: {
			"min-height": "0",
			overflow: "hidden"
		}
	},
	Wrapper: {
		kind: "Box",
		props: {
			"margin-top": "8px",
			"min-height": "0",
			overflow: "hidden"
		}
	},
	"Wrapper :open": {
		kind: "Box",
		props: {
			visibility: "visible"
		}
	},
	"Wrapper :close": {
		kind: "Box",
		props: {
			visibility: "hidden",
			height: 0
		}
	},
	"Wrapper :collapsing": {
		kind: "Box",
		props: {
			height: "0"
		}
	}
}; // src/Collapse/props/propsInfo.js

var propsInfo_default = {
	duration: {
		title: {
			en: "Animation duration",
			ru: "Длительность анимации"
		},
		control: "input",
		variants: ["1s", "1.5s", "2s", "2.5s", "3s", "4s", "5s"],
		type: "text",
		category: "Main",
		weight: 1
	},
	animFunction: {
		title: {
			en: "Smooth animation",
			ru: "Функция сглаживания анимации"
		},
		control: "input",
		variants: ["linear", "ease", "ease-in", "ease-out", "ease-in-out", "step-start", "step-end"],
		type: "text",
		category: "Main",
		weight: 1
	}
}; // src/Collapse/props/propsDefault.js

var propsDefault_default = {
	duration: "1s",
	animFunction: "linear"
}; // src/utils/isEmptyChildren.js

import React from "react";

var isPlaceholder = child => child && child.props && child.props.text === "child placeholder";

var isOverride = child => child && child.props && typeof child.props.slot === "string" && child.props.slot.length > 0;

function isEmptyChildren(children) {
	const childrenArray = React.Children.toArray(children);
	return !childrenArray.some(child => child && !isPlaceholder(child) && !isOverride(child));
} // src/utils/pick.js


var pick = (obj, ...keys) => keys.reduce((result, key) => {
	result[key] = obj[key];
	return result;
}, {});

var pick_default = pick; // src/utils/getAPI.js

var getAPI = () => {
	if (typeof window !== "undefined") {
		return window.QAPI || {};
	}

	if (typeof global !== "undefined") {
		return global.QAPI || {};
	}

	return {};
};

var getAPI_default = getAPI; // src/Collapse/reducer.js

var reducer = (state, action) => {
	const {
		type
	} = action;

	switch (type) {
		case "TOGGLE":
			if (state.destination !== "none") return state;
			return { ...state,
				destination: state.isOpen ? "close" : "open"
			};

		case "COLLAPSING":
			return { ...state,
				isCollapsing: true
			};

		case "COLLAPSE_END":
			return { ...state,
				isCollapsing: false,
				isOpen: state.destination === "open",
				destination: "none"
			};

		default:
			console.warn("Unexpected action in Collapse reducer!");
			return state;
	}
};

var reducer_default = reducer; // src/Collapse/Collapse.js

var pixelTransformer = n => typeof n === "number" && n !== 0 ? `${n}px` : n;

var Collapse = ({
	minDuration,
	maxDuration,
	duration,
	animFunction,
	...props
}) => {
	const ref = useRef();
	const backupStyles = useRef();
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides_default);
	const [{
		destination,
		isOpen,
		isCollapsing
	}, dispatch] = useReducer(reducer_default, {
		destination: "none",
		isOpen: false,
		isCollapsing: false
	});
	const mode = useConstructorMode();
	const toggleOpen = useCallback(() => {
		const isDev = getAPI_default().mode === "development";
		if (isDev && mode === "constructor") return;
		dispatch({
			type: "TOGGLE"
		});
	}, [mode]);
	const collapsedHeight = pixelTransformer(override("Wrapper :close").height) ?? 0;
	const handle = useCallback(e => {
		e?.stopPropagation();
		dispatch({
			type: "COLLAPSE_END"
		});
		Object.assign(ref.current.style, backupStyles.current);
		ref.current.removeEventListener("transitionend", handle);
	}, []);
	useEffect(() => {
		if (destination === "none" || !ref.current) return;
		const {
			style
		} = ref.current;

		if (mode === "constructor") {
			Object.assign(style, {
				transition: "unset"
			});
			handle();
			return;
		}

		backupStyles.current = pick_default(style, "willChange", "overflow", "height", "transition");
		const transition = `height ${duration} ${animFunction}`;
		const expandedHeight = `${ref.current.scrollHeight}px`;
		const [fromHeight, toHeight] = destination === "open" ? [collapsedHeight, expandedHeight] : [expandedHeight, collapsedHeight];
		dispatch({
			type: "COLLAPSING"
		});
		requestAnimationFrame(() => {
			style.willChange = "height";
			style.overflow = "hidden";
			style.height = fromHeight;
			requestAnimationFrame(() => {
				style.transition = transition;
				style.height = toHeight;
			});
		});
		ref.current.removeEventListener("transitionend", handle);
		ref.current.addEventListener("transitionend", handle);
	}, [animFunction, collapsedHeight, destination, duration, handle, mode]);
	return <Box padding="8px" border="1px solid --color-lightD2" border-radius="4px" {...rest}>
		        
		<Box {...override('Button')} onClick={toggleOpen}>
			            
			<Text {...override('Text')} />
			            
			<Image {...override('Image', `Image ${isOpen ? ':open' : ':close'}`)} onClick={toggleOpen} />
			        
		</Box>
		      
    
		<Box ref={ref} {...override("Wrapper", !isCollapsing && `Wrapper ${isOpen ? ":open" : ":close"}`)}>
			<Box {...override("Content")}>
				      
				{children}
				      
				{isEmptyChildren(children) && <Placeholder message="Drop content here" />}
				    
			</Box>
		</Box>
		  
	</Box>;
};

Object.assign(Collapse, {
	title: "Collapse",
	description: {
		en: "This component allows you to collapse the content smoothly",
		ru: "Компонент для плавного переключания видимости содержимого с изменением высоты"
	},
	overrides: overrides_default,
	propInfo: propsInfo_default,
	defaultProps: propsDefault_default
});
var Collapse_default = Collapse;
export { Collapse_default as default };