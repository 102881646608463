import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, LinkBox, Text, Box, Link, List, Section } from "@quarkly/widgets";
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"quarkly-title": "FOOTER",
	"inner-max-width": "1158px",
	"padding": "48px 0 100px 0",
	"as": "footer",
	"background": "#F1ECE9",
	"md-padding": "48px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"width": "20%",
			"lg-width": "50%",
			"lg-margin": "0px 0px 40px 0px",
			"md-width": "100%"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "https://thecoach.onelink.me/EB8r?pid=web&c=web&af_ios_url=https://apps.apple.com/app/id1497286047&af_android_url=https://the.coach/blog&af_web_dp=https://apps.apple.com/app/id1497286047&af_channel=web&af_adset=blog&af_ad=blog",
			"aria-label": "Download the Coach Application",
			"target": "_blank"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/logo_app_green.svg?v=2023-01-27T13:55:17.402Z",
			"display": "block",
			"width": "70px",
			"margin": "0px 0px 42px 0px",
			"lg-margin": "0px 0px 20 0px",
			"alt": "the coach logo"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "300 18px/1.35 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"color": "#565C59",
			"children": "© Vam Apps Co"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "row",
			"align-items": "flex-start",
			"justify-content": "flex-start",
			"margin": "0px 15% 0px auto",
			"lg-width": "100%",
			"sm-width": "45%",
			"width": "40%",
			"lg-order": "-1",
			"lg-margin": "0px 0 30px 0"
		}
	},
	"list": {
		"kind": "List",
		"props": {
			"margin": "0px 80px 0px 0px",
			"padding": "0px 0px 0px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "https://the.coach/",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"children": "Home"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "https://the.coach/blog",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"target": "_blank",
			"children": "Magazine"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "https://www.instagram.com/the_coach_app/",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"target": "_blank",
			"children": "Join community"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "https://www.youtube.com/channel/UC5DFcG0yDj2t6SIUwvSwWTQ",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"target": "_blank",
			"children": "Video tips & workouts"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "/lp/pages-sitemap",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"children": "Sitemap"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"href": "/terms_of_service/",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"children": "Terms of service"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"href": "/privacy_policy/",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"children": "Privacy policy"
		}
	},
	"list1": {
		"kind": "List",
		"props": {
			"margin": "0px 0px 0px 0px",
			"padding": "0px 0px 0px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"link7": {
		"kind": "Link",
		"props": {
			"href": "https://www.instagram.com/the_coach_app/",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"target": "_blank",
			"children": "Instagram"
		}
	},
	"link8": {
		"kind": "Link",
		"props": {
			"href": "https://www.tiktok.com/@the_coach_app",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"target": "_blank",
			"children": "TikTok"
		}
	},
	"link9": {
		"kind": "Link",
		"props": {
			"href": "https://www.youtube.com/channel/UC5DFcG0yDj2t6SIUwvSwWTQ",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"target": "_blank",
			"children": "YouTube"
		}
	},
	"link10": {
		"kind": "Link",
		"props": {
			"href": "https://www.facebook.com/thecoachmenshealthapp?_rdc=1&_rdr",
			"color": "#565C59",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"margin": "0px 0px 17px 0px",
			"display": "block",
			"target": "_blank",
			"children": "Facebook"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"justify-content": "flex-start",
			"width": "20%",
			"margin": "0px 0px 0px 20px",
			"lg-width": "30%",
			"sm-padding": "0px 0px 0px 0px",
			"lg-margin": "0px 10% 0px 0",
			"lg-order": "-1",
			"md-margin": "0px 0 30px 0",
			"md-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 14px 0px",
			"font": "normal 300 18px/25px --fontFamily-sans",
			"color": "#565C59",
			"children": "If you have any questions, Don’t hesitate to contact us by email"
		}
	},
	"link11": {
		"kind": "Link",
		"props": {
			"href": "mailto:support@the.coach",
			"color": "#565C59",
			"text-decoration-line": "underline",
			"font": "--baseSm",
			"children": "support@the.coach"
		}
	}
};

const FooterNew = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			xl-max-width="938px"
			lg-max-width="620px"
			lg-flex-direction="row"
			lg-flex-wrap="wrap"
			lg-justify-content="flex-start"
			lg-align-content="flex-start"
			lg-align-items="flex-start"
		/>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Image {...override("image")} />
			</LinkBox>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<List {...override("list")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
				<Link {...override("link3")} />
				<Link {...override("link4")} />
				<Link {...override("link5")} />
				<Link {...override("link6")} />
			</List>
			<List {...override("list1")}>
				<Link {...override("link7")} />
				<Link {...override("link8")} />
				<Link {...override("link9")} />
				<Link {...override("link10")} />
			</List>
		</Box>
		<Box {...override("box2")}>
			<Text {...override("text1")} />
			<Link {...override("link11")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(FooterNew, { ...Section,
	defaultProps,
	overrides
});
export default FooterNew;