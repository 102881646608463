import React from "react";
import { useOverrides } from "@quarkly/components";
const overrides = {};
const propInfo = {
	eventCategory: {
		name: 'eventCategory',
		control: 'input',
		category: 'Google Analytics',
		weight: 1
	},
	eventLabel: {
		name: 'eventLabel',
		control: 'input',
		category: 'Google Analytics',
		weight: 1
	}
};
const defaultProps = {
	eventCategory: 'button'
};

const GaEventProducer = ({
	eventCategory,
	eventLabel,
	...props
}) => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);

	const onClick = event => {
		console.debug(`GA Event click with category ${eventCategory} and label ${eventLabel}, gtag exists=${typeof gtag !== 'undefined'}`, event);

		if (typeof gtag !== "undefined") {
			gtag('event', 'click', {
				'event_category': eventCategory,
				'event_label': eventLabel
			});
		}
	};

	return <div onClick={onClick} {...rest}>
		{children}
	</div>;
};

Object.assign(GaEventProducer, {
	defaultProps,
	overrides,
	propInfo
});
export default GaEventProducer;