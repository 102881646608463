import React from "react";
import atomize from "@quarkly/atomize";
import YouTubeOptimized from "react-video-content-youtube";

const YoutubeVideo = ({
	children,
	videoId = {
		videoId
	},
	...props
}) => {
	return <div {...props}>
		      
		<YouTubeOptimized src={videoId} params={{
			autoPlay: true,
			...props
		}} />
		    
	</div>;
};

export default atomize(YoutubeVideo)({
	name: "YoutubeVideo",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// past here description for your component
		en: "Youtube video optmizied wrapper"
	},
	propInfo: {
		// past here props description for your component
		videoId: {
			control: "input"
		}
	},
	defaultProps: {
		videoId: "h5X6_6eCTBs"
	}
});