import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, LinkBox, Section } from "@quarkly/widgets";
const defaultProps = {
	"quarkly-title": "HEADER",
	"inner-max-width": "1158px",
	"padding": "24px 0 24px 0",
	"background": "#F3F4F4",
	"border-width": "0 0 1px 0",
	"border-style": "solid",
	"border-color": "#B4B7B5",
	"lg-position": "fixed",
	"lg-top": "0px",
	"lg-z-index": "100"
};
const overrides = {
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "https://the.coach",
			"quarkly-title": "Logo",
			"width": "52px",
			"height": "52px",
			"margin": "0px auto 0px 0px",
			"aria-label": "the Coach homepage",
			"lg-margin": "0px 35px 0px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/logo.svg?v=2023-07-18T11:11:46.784Z",
			"display": "block",
			"alt": "the coach logo"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"quarkly-title": "AppStore",
			"href": "https://apps.apple.com/app/id1497286047?utm_source=buttons_mainsite&utm_medium=mainsite&utm_campaign=web",
			"margin": "0px 10px 0px 0px",
			"lg-display": "none"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07:48:07.383Z",
			"display": "block",
			"alt": "App Store button",
			"height": "50px"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"quarkly-title": "Google Play",
			"href": "https://play.google.com/store/apps/details?id=com.vamapps.thecoach&utm_source=buttons_mainsite&utm_medium=mainsite&utm_campaign=web",
			"lg-display": "none"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07:48:19.068Z",
			"display": "block",
			"alt": "App Store button",
			"height": "50px"
		}
	}
};

const HeaderNew = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			align-items="center"
			height="auto"
			justify-content="flex-end"
			xl-max-width="938px"
			lg-max-width="620px"
			lg-justify-content="flex-start"
		/>
		<LinkBox {...override("linkBox")}>
			<Image {...override("image")} />
		</LinkBox>
		<LinkBox {...override("linkBox1")}>
			<Image {...override("image1")} />
		</LinkBox>
		<LinkBox {...override("linkBox2")}>
			<Image {...override("image2")} />
		</LinkBox>
		{children}
	</Section>;
};

Object.assign(HeaderNew, { ...Section,
	defaultProps,
	overrides
});
export default HeaderNew;